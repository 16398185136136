<template>
  <div>
    <div class="flex flex-col w-full text-center py-2">
      <h1 class="sm:text-3xl text-2xl font-bold mb-4">
        Registra tu cuenta
      </h1>
    </div>
    <div class="flex flex-col text-left py-2">
      <el-form
        ref="registerForm" :model="registerForm" :rules="registerRules" hide-required-asterisk>
        <el-form-item
          label="Nombre completo"
          prop="fullname"
          autocomplete="off">
          <el-input v-model="registerForm.fullname" />
        </el-form-item>
        <el-form-item
          label="Teléfono"
          prop="phone"
          autocomplete="off">
          <sa-phone-country :model="registerForm" />
        </el-form-item>
        <el-form-item
          label="Correo electrónico" prop="email" autocomplete="off">
          <el-input v-model="registerForm.email" />
        </el-form-item>
        <el-form-item label="Elige una contraseña" prop="password">
          <el-input
            :type="showPassword ? 'text' : 'password'"
            v-model="registerForm.password"
            autocomplete="off">
            <span
              class="el-input__icon cursor-pointer text-sm hover:cursor-pointer flex items-center"
              :class="showPassword ? 'text-red-400 hover:text-red-400': 'text-purple-500 hover:text-purple-400'"
              slot="suffix"
              @click="showPassword = !showPassword">
                 <i :class="showPassword ? 'text-xl bx bx-show text-red-500': 'text-xl bx bx-hide'"></i>
              </span>
          </el-input>
        </el-form-item>
        <el-form-item prop="promotionCode">
          <div class="flex flex-row items-start justify">
            <el-checkbox v-model="hasPromotionCode" >Tengo un Código de Promoción</el-checkbox>
          </div>
          <el-input v-if="hasPromotionCode" v-model="registerForm.promotionCode" />
        </el-form-item>
        <el-form-item prop="termsAccepted">
          <div class="flex flex-row items-start justify">
            <el-checkbox v-model="registerForm.termsAccepted" @change="registerForm.termsAccepted = registerForm.termsAccepted === false ? null : true"/>
            <span class="py-1 ml-2 leading-loose">
              He leído y acepto los <router-link class="underline" to="/terms">términos y condiciones</router-link> y la <router-link class="underline" to="/privacy-policy">política de privacidad</router-link>.
            </span>
          </div>
        </el-form-item>
      </el-form>
      <div>
        <span v-if="error" class="text-red-500">{{ error }}</span>
      </div>
      <div class="py-4">
        <sa-button
          class="w-full text-bold rounded"
          type="primary"
          v-loading="buttonLoading"
          element-loading-background="#DDD6FE"
          element-loading-spinner=""
          @click="start()">
            Comenzar
        </sa-button>
      </div>
      <div class="flex flex-col w-full text-center py-2">
        <span>
          ¿Ya tienes cuenta?
          <router-link
            class="text-purple-500 hover:text-purple-400 hover:cursor-pointer"
            to="/login">
            Inicia sesión
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import CatalogService from '@/services/CatalogService';
import CouponsService from '@/services/CouponsService';
import SignService from '@/services/SignService';
import error from '@/mixins/error';

export default {
  name: 'RegisterForm',
  mixins: [
    error
  ],
  data() {
    const validatePromotionCode = (rule, value, callback) => {
      if (!value && this.hasPromotionCode) {
        callback(new Error('Captura tu código de promoción'))
      } else {
        callback();
      }
    };

    return {
      showPassword: false,
      buttonLoading: false,
      error: null,
      registerForm: {
        membershipType: 'basic',
        fullname: null,
        email: null,
        password: null,
        termsAccepted: null,
        promotionCode: null,
        phone: null,
        phoneCountryCodeId: null,
      },
      hasPromotionCode: false,
      registerRules: {
        phone: [ { required: true, message: 'Captura tu telefono', trigger: 'change' } ],
        fullname: [ { required: true, message: 'Captura tu nombre', trigger: 'change' } ],
        email: [ { required: true, type: 'email', message: 'Captura tu email', trigger: 'change' } ],
        password: [ { required: true, message: 'Captura tu contraseña', trigger: 'change' } ],
        termsAccepted: [ { required: true, type: 'boolean', message: 'Aceptar términos y condiciones', trigger: 'change' } ],
        promotionCode: [ { trigger: 'change', validator: validatePromotionCode } ],
        phoneCountryCodeId: [{ required: true, message: "Captura el Código de Area del cliente" }],
      }
    };
  },
  created() {
    this.loadCountries();
    this.registerForm.membershipType = this.$route.query.plan ?? 1;
  },
  methods: {
    async loadCountries() {
      await CatalogService.getFlags();
    },
    async start() {
      this.error = null;
      this.$refs['registerForm'].validate(async (isFormValid) =>{
        if (!isFormValid)
          return false;
        const validCoupon = this.hasPromotionCode ? await this.validateCoupon() : true;
        if (validCoupon) await this.register();
      });
    },
    async validateCoupon() {
      this.buttonLoading = true;
      try {
        const coupons = await CouponsService.check({ PromotionCode: this.registerForm.promotionCode }) || [];
        if (!coupons.length) {
          this.$toastr.e('Código de Promoción Invalido');
          return false;
        }
        return true;
      } catch (e) {
        this.error = this.getErrorMessage(e);
      } finally {
        this.buttonLoading = false;
      }
    },
    async register() {
      try {
        this.buttonLoading = true;
        const payload = {
          membershipType: this.registerForm.membershipType,
          fullname: this.registerForm.fullname,
          password: this.registerForm.password,
          termsAccepted: this.registerForm.termsAccepted,
          promotionCode: this.registerForm.promotionCode,
          email: this.registerForm.email.toLowerCase(),
          phone: this.registerForm.phone,
          phoneCountryCodeId: this.registerForm.phoneCountryCodeId,
        };
        const account = await SignService.register(payload);
        if (account) {
          await this.$router.replace({ name: 'landing.index' });
          this.$swal(
            'Registro exitoso',
            'Un ultimo paso, por favor, comprueba tu direccion de correo electrónico',
            'success',
          );
        }
      } catch(error) {
        this.error = this.getErrorMessage(error);
        return null;
      } finally {
        this.buttonLoading = false;
      }
    },
  },
}
</script>
