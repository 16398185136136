<template>
  <minimal-bg-header-layout header-solo-logo>
    <div>
      <div class="container mx-auto p-6 content-center md:py-12 lg:px-12 md:w-1/2 xl:w-1/3">
        <div class="flex flex-col px-6 py-8 shadow-2xl rounded bg-white lg:px-12">
          <register-form />
        </div>
        <div class="p-2 w-full py-6 mt-12 border-t border-gray-200 text-center">
          <div class="inline-flex">
            <a
              class="mx-4 text-gray-500 hover:text-purple-500"
              href="#">
              Contacto
            </a>
          </div>
        </div>
      </div>
    </div>
  </minimal-bg-header-layout>
</template>
<script>
import MinimalBgHeaderLayout from '@/components/layouts/MinimalBgHeaderLayout.vue';
import RegisterForm from '@/views/public/register/RegisterForm.vue';

export default {
  name: 'RegisterIndex',

  components: {
    MinimalBgHeaderLayout,
    RegisterForm,
  }
}
</script>
