<template>
  <minimal-bg-layout>
    <the-header :solo-logo="headerSoloLogo" />
    <slot />
  </minimal-bg-layout>
</template>
<script>
import MinimalBgLayout from '@/components/layouts/MinimalBgLayout';
import TheHeader from '@/components/organisms/TheHeader'

export default {
  name: 'MinimalBgHeaderLayout',
  components: {
    MinimalBgLayout,
    TheHeader
  },
  props: {
    headerSoloLogo: {
      type: Boolean,
      default: () => false
    },
  }
}
</script>
